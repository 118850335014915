import { createSlice } from "@reduxjs/toolkit";
import {
  CUSTOMER_COLUMN_OPTIONS,
  SALES_ORDER_COLUMN_OPTIONS,
  SALES_INVOICE_COLUMN_OPTIONS,
  SALES_BASED_UPON_ETA_OPTIONS,
  SECONDARY_SALES_INVOICE_OPTIONS,
  EXECUTIVE_BOARD_COLUMN_OPTIONS,
  GENERAL_MANAGER_COLUMN_OPTIONS,
  ZONAL_MANAGER_COLUMN_OPTIONS,
  AREA_SALES_MANAGER_COLUMN_OPTIONS,
  ACCOUNTS_TEAM_COLUMN_OPTIONS,
  SALES_OFFICERS_COLUMN_OPTIONS,
  ADMINS_TEAM_COLUMN_OPTIONS,
  SALES_PICKLIST_COLUMN_OPTIONS,
} from "constants/app.constant";

export const initialState = {
  vendorlist: CUSTOMER_COLUMN_OPTIONS,
  todayOrderlist: SALES_ORDER_COLUMN_OPTIONS,
  todayInvoicelist: SALES_INVOICE_COLUMN_OPTIONS,
  salesBasedUponEtaReports: SALES_BASED_UPON_ETA_OPTIONS,
  secondarySalesInvoice: SECONDARY_SALES_INVOICE_OPTIONS,
  executiveBoard: EXECUTIVE_BOARD_COLUMN_OPTIONS,
  generalManager: GENERAL_MANAGER_COLUMN_OPTIONS,
  zonalManager: ZONAL_MANAGER_COLUMN_OPTIONS,
  areaSalesManager: AREA_SALES_MANAGER_COLUMN_OPTIONS,
  accountsTeam: ACCOUNTS_TEAM_COLUMN_OPTIONS,
  salesOfficers: SALES_OFFICERS_COLUMN_OPTIONS,
  admins: ADMINS_TEAM_COLUMN_OPTIONS,
  salesWithPicklist: SALES_PICKLIST_COLUMN_OPTIONS,

};

export const columnSlice = createSlice({
  name: "column",
  initialState,
  reducers: {
    setStoredColumns: (state, action) => {
      state[action.payload.nameOfPage] = action.payload.columns;
    },
    resetColumns: () => initialState,
  },
});
export const { setStoredColumns, resetColumns } = columnSlice.actions;
export default columnSlice.reducer;
