export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";
export const UNICHARM_AUTH_HEADER_KEY = "Basic c21saWNybTpTbWxpQENSTTI0";

export const APIS = {
  GET_POS_ORDERLIST: "/admin/listPunchedOrders",
  AUTH: {
    LOGIN: "/admin/login",
    LOGOUT: "/admin/logout",
  },
  DASHBOARD: "/admin/dashboard",
  RESET_PASSWORD: "admin/resetPasswordUser",
  BLOCK_ORDER: "/admin/appSettings",
  GET_BLOCK_ORDER_DATA: "/admin/settings",
  PRIMARYT_SALES: {
    GET_PRIMARY_SALES: "/admin/primarySales",
  },
  SALES_INVOICE: {
    GET_SALES_INVOICE: "/admin/salesInvoice",
  },
  SECONDARY_SALES: {
    GET_SECONDARY_SALES: "/admin/secondarySales",
  },
  SALES_BASED_ON_PICKLIST: "/admin/salesBasedPickList",
  EXPORT_MATERIALS_EXCEL_FILE: "admin/exportFile",
  USERS: {
    EDIT_USER: "/admin/addEditUsers",
  },
  IMPORT_EXCEL_FILE: "/admin/importFile",

  LIST_DATA: "/admin/listData",
  PLANTS_CATEGORIES: {
    ADD_EDIT_PLANTS: "/admin/addEditPlants",
  },

  CUSTOMER_CATEGORIES: {
    ADD_EDIT_CUSTOMER: "/admin/addEditCustomer",
  },
  HOT_PRODUCTS: {
    HOT_PRODUCTS_LISTING: "/admin/hotProducts",
    ADD_EDIT_HOT_PRODUCTS: "/admin/hotProducts",
  },

  INVENTORY_REPORT: {
    ADD_EDIT_INVENTORY_REPORT: "/admin/addEditInventory",
  },
  BLOCK_DATA: "/admin/blockData",
  STORAGE_LOCATIONS_CATEGORIES: {
    ADD_EDIT_STORAGE_LOCATIONS: "/admin/addEditStorageLocations",
  },
  INVENTORY_CATEGORIES: {
    ADD_EDIT_INVENTORY: "/admin/addEditProducts",
  },

  ADMIN: {
    CREATE_ADMIN: "/admin/create",
    DELETE_ADMIN: "/admin/delete",
    EDIT_ADMIN: "/admin/editAdmin",
  },

  ADMIN_LIST: "/admin/getAdmin",
  LIST_USERS: "/admin/users",

  CHANGE_PASSWORD: "/admin/changePassword",
  BULK_ORDER_UPLOAD: "/admin/importOrderFile",
  EDIT_PO_NUMBER: "/admin/editPoNumber",
  CREATE_BULK_ORDER: "/admin/createOrder",
  CANCEL_BULK_ORDER: "/admin/cancelOrder",
};
