import React from "react";
import TableSearchBar from "../TableSearchBar";
import { DatePicker, Button, Switcher } from "components/ui";
import { GrPowerReset } from "react-icons/gr";
import { CiFilter } from "react-icons/ci";
import CheckBoxDropdown from "../checkBoxDropdown";
import { checkPermissions, firstDay, lastDay } from "utils/utils";
// import Controlled from "views/ui-components/forms/Switcher/Controlled";
import { useSelector } from "react-redux";
import { ROLES } from "constants/roles.constant";

const FilterBar = ({
  search,
  FilterMenu,
  setSearch,
  setFilterValue,
  filterValue,
  onFilterClick,
  motionFilters,
  handleBtnClick,
  btnLabel,
  placeholder,
  customerCreditLimit,
  btnIcon,
  initialColumns,
  nameOfPage,
  alwaysShowFilter,
  pageName,
}) => {
  const userData = useSelector((state) => state?.auth?.user);
  console.log(userData.userType);

  return (
    <div className="flex flex-wrap w-full items-center justify-between ">
      <div className="flex w-full gap-4 sm:w-8/12 md:w-8/12 lg:w-8/12 xl:w-8/12 pb-2">
        {initialColumns && (
          <CheckBoxDropdown nameOfPage={nameOfPage} columns={initialColumns} />
        )}

        {FilterMenu?.isSearch && (
          <div className="flex w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 ">
            <TableSearchBar
              search={search}
              placeholder={placeholder}
              onChange={(query) => setSearch(query)}
              SearchBarInfo={FilterMenu?.isSearch?.toolTipText}
            />
          </div>
        )}
        {userData.userType === ROLES.SUPER_ADMIN &&
          filterValue?.hasOwnProperty("isUnassigned") && (
            <div className="flex   w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 items-center gap-2 ">
              <Switcher
                onChange={(val) => {
                  setFilterValue({
                    ...filterValue,
                    [FilterMenu?.isToggle?.filterKey]: val,
                  });
                }}
                defaultValue={filterValue.isUnassigned}
              />
              <label className="text-grey-700 font-semibold">
                Filter By Unassigned
              </label>
            </div>
          )}
        {FilterMenu?.isCalendarFilter && (
          <div className="flex w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 ">
            <DatePicker.DatePickerRange
              size="sm"
              dateViewCount={2}
              value={filterValue?.dateRange}
              placeholder={FilterMenu?.isCalendarFilter?.placeholder}
              inputFormat="DD MMMM YYYY"
              onChange={(dateArray) => {
                setFilterValue({
                  ...filterValue,
                  dateRange:
                    dateArray[0] && !dateArray[1]
                      ? [dateArray[0], dateArray[0]]
                      : dateArray,
                });
              }}
            />
          </div>
        )}
      </div>
      <div className="flex w-full sm:w-3/12  md:w-1/12 lg:w-1/12 xl:w-1/12 justify-end pb-2">
        {!alwaysShowFilter &&
          FilterMenu?.isFilterButton &&
          !customerCreditLimit && (
            <Button
              size="sm"
              className={`mr-2 ${motionFilters && "text-[#F97316]"}`}
              onClick={onFilterClick}
              icon={<CiFilter color={motionFilters && "#F97316"} />}
            >
              Filter
            </Button>
          )}
        {FilterMenu?.isResetButton && (
          <Button
            size="sm"
            onClick={() => {
              let obj = {};
              Object.keys(filterValue).forEach((item) => {
                obj[item] = FilterMenu?.isResetButton?.filterKey[item];
              });
              obj.dateRange = [firstDay, lastDay];
              setFilterValue(obj);
              setSearch("");
            }}
            icon={<GrPowerReset />}
          >
            Reset
          </Button>
        )}
        {userData.userType === ROLES.SUPER_ADMIN &&
          checkPermissions(pageName, "addEdit") &&
          btnLabel && (
            <Button
              variant="solid"
              size="sm"
              className="ml-2"
              onClick={handleBtnClick}
              icon={btnIcon || ""}
            >
              {btnLabel}
            </Button>
          )}
      </div>
    </div>
  );
};
export default FilterBar;
