const appConfig = {
  apiBaseUrl:
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? "https://uciasampada.com/devapi"
      : "https://uciasampada.com/services",
  apiPrefix: "/api",
  authenticatedEntryPath: "app/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  enableMock: false,
};

console.log("process.env.NODE_ENV", process.env.REACT_APP_ENVIRONMENT);

export default appConfig;
