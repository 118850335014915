export const ADMIN = "admin";
export const USER = "user";

export const ROLES = {
  SUPER_ADMIN: 1,
  EXECUTIVE_BOARD: 2,
  GENERAL_MANAGER: 3, // 3 & 4  type roles is same
  ZONAL_MANAGER: 4,
  AREA_SALES_MANAGER: 5,
  ACCOUNTS_TEAM: 6,
  SALES_OFFICER: 7,
};
