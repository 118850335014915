import { Checkbox, Tooltip } from "components/ui";
import React, { useEffect, useState, useRef } from "react";
import { IoSettings } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { setStoredColumns } from "store/base/columns";

const CheckBoxDropdown = ({ columns, nameOfPage }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const handleColumnToggle = (column) => {
    const updatedColumns = columns?.map((col) =>
      col.value === column.value ? { ...col, visible: !col.visible } : col
    );

    dispatch(setStoredColumns({ nameOfPage, columns: updatedColumns }));

    const isAllSelected = updatedColumns.every((col) => col.visible);
    setAllSelected(isAllSelected);
  };

  const toggleSelectAll = () => {
    const newVisibility = !allSelected;
    const updatedColumns = columns?.map((col) => ({
      ...col,
      visible: newVisibility,
    }));
    dispatch(setStoredColumns({ nameOfPage, columns: updatedColumns }));
    setAllSelected(newVisibility);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block">
      <Tooltip title="Select Columns" placement="top">
        <button
          ref={buttonRef}
          className="p-2 bg-orange-100 flex items-center justify-center rounded-md focus:outline-none"
          onClick={toggleDropdown}
        >
          <IoSettings className="text-[#F97316] text-[18px]" />
        </button>
      </Tooltip>
      {dropdownOpen && (
        <div
          className="absolute left-1 mt-2 w-48 bg-white shadow-lg rounded-md p-3 z-50"
          ref={dropdownRef}
        >
          <label className="flex items-center mb-2">
            <Checkbox checked={allSelected} onChange={toggleSelectAll} />
            <span className="ml-2">Select All</span>
          </label>
          {columns?.map((column) => (
            <label key={column.id} className="flex items-center mb-2">
              <Checkbox
                checked={column.visible}
                onChange={() => handleColumnToggle(column)}
              />
              <span className="ml-2">{column.label}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default CheckBoxDropdown;
