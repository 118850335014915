import React from "react";
import { Input, Tooltip } from "components/ui";
import { HiOutlineSearch } from "react-icons/hi";
import { BsFillInfoCircleFill } from "react-icons/bs";

const TableSearchBar = ({ onChange, SearchBarInfo, placeholder, search }) => {
  const handleInputChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <Input
      value={search}
      size="sm"
      placeholder={placeholder}
      prefix={<HiOutlineSearch className="text-lg" />}
      suffix={
        <Tooltip title={placeholder}>
          <BsFillInfoCircleFill className="text-lg" />
        </Tooltip>
      }
      onChange={handleInputChange}
    />
  );
};

export default TableSearchBar;
